<template>
  <section class="theme-wrap">
    <!-- <side-bar/> 
    <section class="theme-content"> -->
      <page-header></page-header>
      <router-view />
    <!-- </section> -->
  </section>
</template>

<script>
import SideBar from '@/components/SideBar'
import pageHeader from '@/components/pageHeader'
export default {
  components: {
    SideBar, pageHeader
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/index';
.theme-wrap {
  width: 100%;
  height: 100%;
  /* padding: 30px 30px;
  display: flex;
  .theme-content {
    width: 100%;
    min-height: $contentHeight;
    margin-left: 30px;
  } */
}
</style>
